#hero{
    border-bottom: 1px solid #D6F1FF;
    border-top: 1px solid #D6F1FF;
    padding: 65px 0;
    background: #FCFEFF;
}
.heroWrapper{
    .heroText{
        margin-bottom: 70px;
        text-align: center;
        font-size: 40px;
    }
    .heroItem{
        text-align: center;
        .hero-img{
            margin-bottom: 25px;
        }

        .hero-title{
            font-size: 24px;
            color: #F7918F;
            line-height: 28px;
        }

        .hero-text{
            font-size: 18px;
            color: #2B244E;
            line-height: 21px;
            font-weight: 300;
            margin-top: 10px;
        }

        .hero-link{
            margin-top: 25px;
            color: #7B7599;
            font-size: 17px;
            font-weight: 500;
            display: block;
        }
    }
}