footer{
    padding: 30px 0;
    min-height: 100px;
    background: url('../assets/img/footer.png') #FCFEFF no-repeat;
    border-top: 1px solid #D8E4ED;

    .footer-branding{
        text-align: right;
        li{
            display: inline-block;
            margin-left: 60px;
        }
    }

    .footer-smaltext{
        font-size: 14px;
        color: #2B244E;

        .right-text{
            text-align: right;
        }
    }
}