#topBar{
    #topBarWrapper{
        border-bottom: 1px solid #EBEBEB;
        .topBar-navigation{
            margin: 0;
            padding: 12px 0;
            li{
                display: inline-block;
                list-style: none;
                margin-left: 33px;
                &:first-child{
                    margin-left: 0px;
                }
                a{
                    color: #F7918F;
                    font-weight: normal;
                    span.topBar-icon{
                        margin-right: 8px;
                        font-size: 18px;
                    }

                    span.topBar-text{
                        line-height: 18px;
                        font-size: 15px;
                    }
                }
            }
        }
        
        .topBar-right-navigation{
            margin: 0;
            padding: 12px 0;
            li{
                display: inline-block;
                margin-left: 23px;
                font-size: 15px;
                &.icon{
                    font-size: 18px;
                }
                &.colored{
                    a{
                        color: #F7918F;
                    }
                }

                a{
                    color: #7B7599;
                }
            }
        }
    }
}