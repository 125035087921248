#home-galery{
    margin-top: 130px;
    padding-bottom: 130px;
    border-bottom: 1px solid #D6F1FF;;
    .galery-items{
        margin-top: 77px;
        .item{
            text-align: center;
            .galery-image{
                width: 210px;
                height: 210px;
                box-shadow: 0px 8px 20px rgba(125, 160, 219, 0.3), inset 0px 15px 30px rgba(255, 255, 255, 0.8);
                opacity: 0.5;
                border-radius: 40%;
                overflow: hidden;
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                transition: opacity 1s, box-shadow 1s;
            }

            .galery-text{
                color: #B1B1B1;
                font-weight: 300;
                font-size: 20px;
                line-height: 125%;
                margin-top: 20px;
            }

            &:hover{
                cursor: pointer;
               .galery-image{
                transition: opacity 1s, box-shadow 1s;
                opacity: 1;
                -webkit-filter: none; /* Safari 6.0 - 9.0 */
                filter: none;
                box-shadow: 0px 8px 20px rgba(247, 145, 143, 0.3), inset 0px 15px 30px rgba(255, 255, 255, 0.8);
               }

               .galery-text{
                   color: #F87C7A;
               }
            }
        }
    }
}

.knowledgebase{
    background: url('../assets/img/bg.png');
    padding-top: 80px;
    padding-bottom: 80px;
    .questions{
        margin-top: 60px;
        .question{
            padding: 30px;
            background: #fff;
            border: 1px solid #C1E2F2;
            border-radius: 8px;
            margin-bottom: 40px;
            .question-text{
                color: #2B244E;
                font-size: 20px;
                line-height: 125%;
            }
        }
    }
}

