#slider{
  position: relative;
  display: flex;
  align-items: center;
  padding: 100px 0;
 &:after{
   content: '';
   background: url('../assets/img/slider.png') no-repeat;
   background-size: 80%;
   position:absolute;
   left: 0;
   bottom: 0;
   width: 45%;
   height: 300px;
   z-index: -1;
 }

  .slider-wrapper{
    margin: 0px 60px;
    .slide{
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .slide-image{
        margin-left: 40px;
        img{
          width: 400px;
        }
        .slide-copyright{
          font-size: 13px;
          color: #656565;
          text-align: right;
          margin-top: 5px;
        }
      }

      .slide-text{
        h1{
          font-weight:300;
        }
      }
    }
  }
}