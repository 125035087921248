header{
    #headerWrapper{
        padding: 20px 0;
        nav{
            margin-left: 45px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    list-style: none;
                    display: inline-block;
                    margin-right: 25px;
                    a{
                        font-size: 17px;
                        line-height: 20px;
                        color: #7B7599;
                    }
                }
            }
        }

        a.btn-pink{
            color: #fff !important;
        }
    }
}