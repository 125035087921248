.helper-text{
    margin-top: 80px;
    color: #2B244E;
    text-align: center;
    letter-spacing: -0.03em;
    font-size: 20px;
    line-height: 125%;
    font-weight: 300;
}

.packs-place-filter{
    width: 90%;
    margin: 0px auto;
    .packs-place-filter-title{
        margin-top: 82px;
        margin-bottom: 17px;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #2B244E;
    }

    .matter-checkbox{
        margin-right: 82px;
    }

    margin-bottom: 42px;
}

.noway{
    opacity: 0.3;
    user-select: none;
}

.packs-card{
    margin:0px auto 80px auto;
    padding: 20px 40px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    width: 90%;
    .packs-title{
        font-size: 20px;
        line-height: 36px;
        color: #2B244E;
        margin-bottom: 15px;
    }

    .packs-cost{
        font-size: 20px;
        line-height: 36px;
        color: #F7918F;
    }

    .packs-image{
        width: 340px;
        margin-right: 40px;
    }

    .packs-tab{
        ul{
            margin: 0;
            padding: 0;
            margin-bottom: 20px;
            li{
                list-style: none;
                display: inline-block;
                margin-right: 40px;
                font-size: 16px;
                line-height: 24px;
                color: #989898;
                font-weight: 300;
                padding-bottom: 14px;
                cursor: pointer;
                span{
                    padding: 0 10.5px;
                }
                &.tab-active{
                    border-bottom: 1px solid #F7918F;
                    color: #F7918F;
                }
            }
        }

        .tab-content-wrapper{
            margin-bottom: 35px;
            .tab-content{
                display: none;
                &:first-child{
                    display: block;
                }
            }
        }
    }
}

.loading{
    position: fixed;
    background: rgba(255,255,255,.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}