@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500&display=swap');
body{
    margin:0;
    padding:0;
    font-family: 'Rubik';
}

@import 'components/vendor';

@import 'components/topbar';
@import 'components/header';
@import 'components/slider';
@import 'components/hero';
@import 'components/homegallery';
@import 'components/form';
@import 'components/packs';
@import 'components/footer';
