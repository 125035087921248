.home-title{
    .home-title-hero{
        font-size: 40px;
        line-height: 125%;
        letter-spacing: -0.03em;
        color: #F7918F;
    }

    .home-title-desc{
        font-size: 20px;
        line-height: 125%;
        color: #2B244E;
    }
}

.btn-purple{
    background-color: #7B7599;
    color: #fff !important;
    &:hover{
        color: #fff;
    }
}

.btn-white{
    border: 1px solid #F7918F;
    color: #F7918F;
    &:hover{
        color: #F7918F;
    }
}

.btn-pink{
    border: none;
    background: #F7918F;
    color: #fff;
    &:hover{
        background: #F7918F;
    }
}


.breadcrumbs{
    border: 1px solid #EBEBEB;
    padding: 12px 0;
    ul{
        list-style: none;
        padding:0;
        margin: 0;
        li{
            display: inline-block;
            color: #7B7599;
            font-size: 14px;
            line-height: 17px;
            &:after{
                content: '\f105';
                font-family: "Font Awesome 5 Pro";
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
            a{
                color: #7B7599;
            }
        }
    }
}

.page-title{
    font-weight: 300;
    font-size: 50px;
    line-height: 125%;
    padding: 30px 0px;
    text-align: center;
    border-bottom: 1px solid #D6F1FF;
    background: #FCFEFF;
}

.page-small-title{
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 125%;
    margin-top: 50px;
    padding: 0px 10px;
}
