.form-wrapper{
  border: 1px solid #F0F0F0;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-top: 80px;
  padding: 40px;

  .page-form-label{
    font-size: 22px;
    line-height: 26px;
    color: #2B244E;
    margin-bottom: 18px;
  }

  .page-custom-radio{
    margin-bottom: 30px;
    label{
      margin-right: 30px;
    }
  }

  .page-custom-button{
    margin-top: 6px;
    width: 100%;
    padding: 18px 13px;
    line-height: 19px;
    border-radius: 6px;
    text-transform: uppercase;
    &:focus{
      outline:0;
    }
  }

  .second-line{
    margin-top: 15px;
  }

  .matter-textfield-outlined{
    width: 100%;
  }

  .flagArea{
    position: relative;
    .flagDropdown{
      position: absolute;
      top: 7px;
      left: 1px;
      .btn-flags{
        background: #F7F7F7;
        color: #8F959C;
        padding: 12px;
        &:focus{
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .flag-input{
      padding-left: 80px;
      border-top: 1px solid var(--matter-helper-safari2) !important;
      &:focus{
        border-top-color: var(--matter-helper-theme) !important;
      }
    }
  }

  .select {
    position: relative;
    width: 100%;
    margin-top: 7px;
  }

  .select-text {
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    padding: 16px 10px;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    border: 1px solid rgba(0,0,0, 0.60);
  }

  /* Remove focus */
  .select-text:focus {
    outline: none;
    border: 1px solid #ED6600;
    border-radius: 3px;
  }

  /* Use custom arrow */
  .select .select-text {
    appearance: none;
    -webkit-appearance:none
  }

  .select:after {
    position: absolute;
    top: 25px;
    right: 10px;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }


  /* LABEL ======================================= */
  .select-label {
    color: rgba(0,0,0, 0.6);
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 15px;
    transition: 0.2s ease all;
  }

  /* active state */
  .select-text:focus ~ .select-label {
    color: #ED6600;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    top: -9px;
    transition: 0.2s ease all;
    font-size: 12px;
  }

  .select-text:valid ~ .select-label {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    top: -9px;
    transition: 0.2s ease all;
    font-size: 12px;
    content: 'mine'
  }

  /* BOTTOM BARS ================================= */
  .select-bar {
    position: relative;
    display: block;
    width: 320px;
  }


  .select-bar:before {
    left: 50%;
  }

  .select-bar:after {
    right: 50%;
  }

  /* active state */
  .select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

}
